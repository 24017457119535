<template>
  <div class="single">
    <b-container>
    <b-row>
      <b-col>
        <span v-html="wpInfo[0].content.rendered"></span> 
      </b-col>
    </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "contact",
  data() {
    return {
      wpInfo: []
    };
  },
  mounted() {
    axios //add API Call
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/pages?slug="+this.$route.name) // Page number changed on each page based on JSON page id you are calling
      .then(response => (this.wpInfo = response.data));
  }
};
</script>

<style scoped>
.single {
  background-color: #f1f1f1;
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
}

iframe {
  width: 600px;
  height: 500px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 600px;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 600px;
}
::v-deep a{
  color: #519c1f;
}
::v-deep .wp-block-image img {
    max-width: 100%;
    object-fit: contain;
}
::v-deep .wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto;
}
::v-deep .wp-block-image {
    margin-bottom: 1em;
}
v-deep figure {
    margin: 0;
}
::v-deep .wp-block-file .wp-block-file__button {
    background: #32373c;
    border-radius: 2em;
    color: #fff;
    font-size: 13px;
    padding: .5em 1em;
    margin-left: .75em;
}
::v-deep li{
  list-style: none;
}
@media only screen and (max-width: 480px) {
  iframe {
    width: 350px;
    height: 350px;
  }
  .mapouter {
    text-align: left;
    height: 350px;
    width: 350px;
  }
  .gmap_canvas {
    height: 350px;
    width: 350px;
}
}
</style>
