<template>
  <div id="schedule">
    <b-card title="Card Title" no-body>
      <b-card-header header-tag="nav">
        <b-nav card-header pills>
          <b-dropdown :text="seasonDes(id)" class="dropdownSeason">
            <b-dropdown-item
              v-on:click="changeYear"
              v-for="(seasons,
              index) in tm_params.seasons.seasons_entry.slice().reverse()"
              :key="index"
              :title="seasons.code"
              :year="seasons.code"
              >{{ seasons.desc }}</b-dropdown-item
            >
          </b-dropdown>
        </b-nav>
      </b-card-header>
      <ScheduleTable
        :fields="fields"
        :fieldsPast="fieldsPast"
        :id="id"
        :data="data"
        :code='course'
      />
    </b-card>
  </div>
</template>

<script>
import ScheduleTable from "@/components/scheduleTable.vue";
import axios from "axios";
export default {
  name: "schedule",
  props:['course'],
  components: {
    ScheduleTable,
  },
  data() {
    return {
      id: [],
      tm_params: [],
      data: [],
      fieldsPast: [
        { key: "start_date", label: "Dates" },
        { key: "full_name", label: "Tournament" },
        { key: "course", label: "Venue" },
        { key: "total_prize_fund", label: "Prize Fund" },
        { key: "reports", label: "Reports", class:"reportHide" },
      ],
      fields: [
        { key: "start_date", label: "Dates" },
        { key: "full_name", label: "Tournament" },
        { key: "course", label: "Venue" },
        { key: "is_closed", label: "Enter" },
        { key: "reports", label: "Reports", class:"reportHide"},
      ],
    };
  },
  methods: {
                seasonDes(id){
         var year = this.tm_params.seasons.seasons_entry.filter((seas) => seas.code === id)
      return year[0].desc
    },
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (
        (this.id = id),
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
              this.id +
              "/tmticx?randomadd=" +
              new Date().getTime()
          )
          .then(
            (response) =>
              (this.data = response.data.tournaments.tournaments_entry)
          )
      );
    },
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_TIC_BASE +
          process.env.VUE_APP_ID +
          "/tmticx?randomadd=" +
          new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        (this.tm_params = response.data),
          (this.id = response.data.tm_params.season_code);
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID +
            "/" +
            this.id +
            "/tmticx?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.data = response.data.tournaments.tournaments_entry;
      });
  },
};
</script>

<style scoped>
::v-deep .mt-3, .my-3 {
  margin-top: 0px!important;
}
::v-deep .card-body.text-center.table-responsive {
  padding-top: 0px;
}
::v-deep .dropdownSeason > button{
  background-color: #2f8d3e;
}
#schedule {
  padding: 0 200px;
  background-color: rgba(0, 0, 0, 0.03);
}
::v-deep button {
  color: white;
  background-color: #33cd5f;
  position: relative;
  top: -4px;
}
::v-deep button:hover {
  color: #2f8d3e;
  background-color: white;
}
::v-deep th {
  color: white;
  background-color: #2f8d3e;
}
::v-deep tr:nth-child(even) {
  background-color: #f2f2f2;
}
.card {
  border: 0;
}
::v-deep .card-body{
  padding: 1.25rem 0;
}
@media only screen and (max-width: 1366px) {
  #schedule {
    padding: 0 100px;
  }
}
@media only screen and (max-width: 990px) {
  #schedule {
    padding: 0 50px;
  }
}
@media only screen and (max-width: 480px) {
  #schedule {
    padding: 0 20px;
  }
  ::v-deep th.reportHide {
    display: none;
  }
  ::v-deep td.reportHide {
    display: none!important;
  }
}
</style>
