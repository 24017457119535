<template>
<span class="backNews">
  <div class="backNews">
    <b-container class="backNews">
        <h3 class="titleHead">
          Latest News
        </h3>
      <b-row class="space" v-for="(posts, index) in posts" :key="index">
        <b-col lg='6' md='12' cols='12' class="order-2">
          <h1 class="titleRenderedNews">
            {{ posts.title.rendered }}
          </h1>
          <span v-html="posts.excerpt.rendered"></span>
          <b-nav-item :to="'/' + posts.slug">
            Full Story
          </b-nav-item>
        </b-col>
        <b-col lg='6' md='12' cols='12' class="order-1">
          <b-img v-if="posts.better_featured_image !== null"
            :src="posts.better_featured_image.source_url"
            fluid
            alt="Responsive image"
          />
          <b-img
            v-else
            src="/wp-content/uploads/2021/12/IGT-Edugolf-Logo-1024x1000-1.jpeg"
            fluid
            alt="Responsive image"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</span>

</template>

<script>
import axios from "axios";
export default {
  name: "posts",
  data() {
    return {
      posts: []
    };
  },
  mounted() {
    axios //add API Call
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/posts") // Page number changed on each page based on JSON page id you are calling
      .then(response => (this.posts = response.data));
  }
};
</script>

<style scoped>
.backNews {
  background-color: #f1f1f1 !important;
}
h1.titleRenderedNews {
  color: #519c1f;
  font-size: 30px;
}
::v-deep .nav-link {
  color: white;
  background-color: #2F8D3E;
  width: 21%;
  text-align: center;
  border-radius: 0rem;
}
li{
  list-style: none;
}
.space {
  padding-bottom: 2%;
  border-bottom: 1px solid lightgrey;
  padding-top: 2%;
}
.titleHead {
  color: #2f8d3e;
  padding: 40px 0 15px;
  font-size: 18pt;
}
@media only screen and (max-width: 480px) {
  .col {
    flex-basis: auto;
  }
  ::v-deep .nav-link {
    width: 30%;
    text-align: center;
  }
  ::v-deep .row {
    padding-bottom: 4%;
  }
    .titleHead {
    text-align: center;
  }
}
</style>
