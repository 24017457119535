<template>
  <div>
    <div class="top">
      <div>
        <b-container class="selectRD">
          <b-row>
            <b-col lg='6' md='4' cols='12' order='2' order-lg='1' order-md='1' class="dropSel">
              <div :class="drop">
                <b-nav card-header pills>
                  <b-dropdown
                    v-if="Array.isArray(tmparams.reports.reports_entry)"
                    :text="title"
                  >
                    <b-dropdown-item
                      v-on:click="changeReport"
                      v-for="(reports, index) in filterExemptions"
                      :key="index"
                      :title="reports.report_url"
                      :value="reports.report_title"
                      >{{ reports.report_title }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown v-else text="Please Select a Report">
                    <b-dropdown-item
                      v-on:click="changeReport"
                      v-for="(reports, index) in tmparams.reports"
                      :key="index"
                      :title="reports.report_url"
                      :value="reports.report_title"
                      >{{ reports.report_title }}</b-dropdown-item
                    >
                  </b-dropdown>
                </b-nav>
              </div>
            </b-col>
            <b-col lg='6' md='8' cols='12' order='1' order-lg='1'>
              <b-img class="sponsor" :src="tmparams.sponsor_logo"></b-img>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- <div class="tourTitle">
        <b-row>
          <b-col style="text-align:center;">
            <h3 class="tournHead">
              {{ data.full_name }}
            </h3>
            <h3 class="tournHead dates">
              {{ data.course_dates }}
            </h3>
          </b-col>
        </b-row>
      </div> -->
    </div>
    <div class="mid">
      <div class="reports">
        <template
          v-if="
            this.currentReport.indexOf('tmentry') !== -1 ||
              this.currentReport.indexOf('tmdraw') !== -1 ||
              this.currentReport.indexOf('tmresult') !== -1 ||
              this.currentReport.indexOf('tmoomt') !== -1 ||
              (this.currentReport.indexOf('tmscore') !== -1 &&
                data.match_play === 'Y')
          "
        >
          <ReportTable
            :data="data"
            :home="home"
            :title="title"
            :config="config"
            :team="teamEvent"
            :match="tmparams.match_play"
            :season="tmparams.tm_params.season_code"
          />
        </template>
        <template
          v-if="
            this.currentReport.indexOf('tmscore') !== -1 ||
              this.currentReport.indexOf('tmlsmon') !== -1
          "
        >
          <b-tabs>
            <b-tab title="Leaderboard" active>
              <ReportTable
                :data="data"
                :previousData="previousData"
                :home="home"
                :title="title"
                :config="config"
                :team="teamEvent"
                :season="tmparams.tm_params.season_code"
              />
            </b-tab>
            <b-tab title="Hole By Hole">
              <HBH
                :data="data"
                :scores="data.scores.scores_entry"
                :previousData="previousData"
                :config="config"
              />
            </b-tab>
          </b-tabs>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ReportTable from "@/components/reportTable.vue";
import HBH from "@/components/hbh.vue";

export default {
  name: "Reports",
  props: ["config"],
  components: {
    ReportTable,
    HBH,
  },
  data() {
    return {
      data: [],
      previousData: [],
      tmparams: [],
      drop: "drop",
      currentReport: this.$route.query.url,
      home: false,
      tabs: true,
      title: this.$route.query.title,
    };
  },
  methods: {
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      this.title = title;
      return (
        (this.currentReport = report),
        axios
          .get(this.reportTitle)
          .then((response) => (this.data = response.data))
      );
    },
    updateStuff: function() {
      axios
        .get(this.reportTitle)
        .then((response) => {
          this.data = response.data;
          return axios.get(
            "https://igtgolf.org/api/igt/cache/igt/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          );
        })
        .then((response) => {
          this.tmparams = response.data;
        });
    },
  },
  computed: {
    filterExemptions: function() {
      return this.tmparams.reports.reports_entry.filter(
        (reports_entry) =>
          !reports_entry.report_title.indexOf("Tournament Entries") ||
          !reports_entry.report_title.includes("Draw") ||
          !reports_entry.report_title.includes("Scores") ||
          !reports_entry.report_title.icludes("Scoreboard") ||
          !reports_entry.report_title.indexOf("Final Result") ||
          !reports_entry.report_title.indexOf("Live Scoring Monitor")
      );
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_TIC_BASE +
          process.env.VUE_APP_ID +
          "/" +
          this.$route.query.id +
          "/" +
          this.$route.query.id +
          "-" +
          this.$route.query.code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  mounted() {
    axios
      .get(this.reportTitle)
      .then((response) => {
        this.data = response.data;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-tmticx.json?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.tmparams = response.data;
      });
  },
};
</script>

<style scoped>
.main {
  padding-top: 2%;
  padding-bottom: 2%;
}
.tourTitle {
  background-color: #fff;
  text-align: center;
  padding: 20px 0;
}

::v-deep .dropdown-toggle {
  background-color: #2f8d3e;
  border-radius: 0;
}
.sponsor {
  max-width: 126px;
  vertical-align: middle;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 5% !important;
  margin-left: 47% !important;
}
::v-deep .nav-link.active {
  color: white !important;
  background-color: #2f8d3e !important;
  width: 100%;
  border-color: #2f8d3e !important;
  height: 100%;
}

::v-deep .nav-link {
  color: black;
  width: 100%;
  font-size: 18pt;
}
.dropSel {
  padding: 3% 0%;
}
::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}
.tourLogoS {
  max-width: 180px;
  margin: 0;
  height: auto;
  margin-bottom: 56px;
  display: inline-block;
  max-height: 150px;
}
.tournHead {
  color: #2f8d3e;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 24px;
}

.dates {
  font-size: 16px !important;
}

.top {
  background-color: #ebebeb;
}
.mid {
  background-color: #fff;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .sponsor {
    margin-left: 35%!important;
  }
  .drop {
    padding-left: 20px;
  }
  .drop>ul>div{
    width: 95%;
  }
  .selectRD {
    padding: 2% 4%;
  }
}
</style>
