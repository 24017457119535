<template>
  <div class="pad">
    <b-row class='pad' style="background-color:##f1f1f1;">
      <b-col class='col-md-6 col-12'>
        <a href="/news">
          <button class="backNews">
          Back to news
          </button>
        </a>
        <b-img class='postImg' :src="postInfo[0].better_featured_image.source_url"></b-img>
        <p>{{postInfo[0].date}}</p>
      </b-col>
      <b-col class='col-md-6 col-12'>
        <span class="title" v-html="postInfo[0].title.rendered"></span>
        <span v-html="postInfo[0].content.rendered"></span>
      </b-col>
    </b-row>
    <h3 class="titleHead Latestnews">
      IGT Golf News
    </h3>
    <Post :home='home' :config='config'/>  
  </div>
</template>

<script>
import Post from '@/components/posts.vue'
import axios from "axios";
export default {
  name: "postFull",
  props: ["id", 'config'],
  components:{
    Post
  },
  data() {
    return {
      postInfo: [],
      home:false
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/posts?slug=" + this.id)
      .then(response => (this.postInfo = response.data));
  }
};
</script>

<style scoped>
button.backNews:hover {
  color: #fff;
  background-color: #5a6268;
  text-align: center;
  border-radius: 0;
  padding: 10px;
  border: none;
  margin-bottom: 10px;
}
button.backNews {
  color: #fff;
  background-color: #2f8d3e;
  text-align: center;
  border-radius: 0;
  padding: 10px;
  border: none;
  margin-bottom: 10px;
}
h3.Latestnews {
  background-color: #dfe1e6;
  color: #2F8D3E;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 2em;
}
.postImg{
  width: 100%;
}
.titleHead {
  color: #2f8d3e;
  padding-bottom: 15px;
}
.pad{
  padding: 20px;
  background-color: #f1f1f1;
}
span.title {
  color: #2F8D3E;
  font-size: 30px;
}
</style>
