<template>
  <div class="stats">
    <b-card title="Card Title" no-body>
      <b-card-header header-tag="nav">
        <b-row class='btnRow'>
          <b-col lg='3' md='6' cols='12' class='center'>
            <b-dropdown :text="seasonDes(id)">
              <b-dropdown-item
                v-on:click="changeYear"
                v-for="(seasons,
                index) in tm_params.seasons.seasons_entry.slice().reverse()"
                :key="index"
                :title="seasons.code"
                :year="seasons.code"
                >{{ seasons.desc }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col lg='3' md='6' cols='12' class='center'>
            <b-dropdown v-if="stats === ''" :text="'Report'">
              <b-dropdown-item>No Stats</b-dropdown-item>
            </b-dropdown>
            <b-dropdown v-else :text="data.short_name">
              <b-dropdown-item
                v-for="(order, index) in stats.ooms.ooms_entry"
                :key="index"
                v-on:click="pickStat"
                :title="'ooms-oom-' + order.code"
                >{{ order.short_name }}</b-dropdown-item
              >
              <b-dropdown-item
                v-for="(order, index) in stats.stats.stats_entry"
                :key="index"
                v-on:click="pickStat"
                :title="'stats-stats-' + order.code"
                >{{ order.short_name }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
      </b-card-header>
      <b-row>
        <b-col>
          <h3 class='tournHead'>
          {{data.full_name}}
          </h3>
        </b-col>
      </b-row>
      <StatsTable :config='config' v-if="stats.length === 0" :id="id" />
      <StatsTable :config="config" v-else :id="id" :stat="stat" :data="data" />
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import StatsTable from "@/components/statsTable.vue";

export default {
  name: "stats",
  props: ["season", 'config'],
  components: {
    StatsTable,
  },
  data() {
    return {
      stats: [],
      id: this.season,
      stat: "B1",
      data: [],
      tm_params: [],
    };
  },
  methods: {
    seasonDes(id){
      var year = this.tm_params.seasons.seasons_entry.filter((seas) => seas.code === id)
      return year[0].desc
    },
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (
        (this.id = id),
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
              this.id +
              "/tmticx?field=Y&fields=ooms&fields=stats&randomadd=" +
              new Date().getTime() 
          )
          .then((response) => {
            this.stats = response.data.ooms.ooms_entry;
            return axios.get(
            process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
                this.id +
                "/" +
                this.id +
                "-" +
                this.stat +
                ".json?randomadd=" +
                new Date().getTime()
            );
          })
          .then((response) => {
            this.data = response.data;
            console.log("this.data")
            console.log(this.data)
          })
      );
    },
    pickStat: function(event) {
      var stat = event.target.getAttribute("title");
      return (
        (this.stat = stat),
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
              this.id +
              "/" +
              this.id +
              "-" +
              this.stat +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then((response) => (this.data = response.data))
      );
    },
  },
  mounted() {
    axios //add API Call
      .get(
            process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" + "tmticx?randomadd=" +
          new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.tm_params = response.data;
        return axios.get(
            process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
            this.tm_params.tm_params.season_code +
            "/tmticx?field=Y&fields=ooms&fields=stats&randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.stats = response.data;
        return axios.get(
            process.env.VUE_APP_TIC_BASE +
              process.env.VUE_APP_ID +
              "/" +
          this.season +
          "/" +
          this.season +
          "-ooms-oom-"+process.env.VUE_APP_OOM+".json?randomadd=" +
          new Date().getTime()
        );
      })
      .then((response) => {
        this.data = response.data;
      });
  },
};
</script>

<style scoped>
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
  background: white;
  padding-top: 15px;
  padding-bottom: 15px;
}
::v-deep .btn {
  background-color: #2f8d3e;
}
.dropdown{
  width: 80%;
}
.center{
  text-align: center;
}
.tournHead{
  text-align: center;
  color: #2F8D3E;
  margin-top: 15px;
  margin-bottom: 15px;
}
::v-deep .cell {
  width: 15%;
  text-align: center;
  padding: 13px;
}
@media only screen and (max-width: 767px) {
  .dropdown{
    width: 100%;
    padding: 10px 0;
  }
}

</style>
