<template>
  <b-tabs content-class="mt-3">
    <b-tab title="Past">
      <b-card-body class="text-center table-responsive">
        <b-table
          :items="pastEventFilter"
          :fields="fieldsPast"
          :tbody-tr-class="rowClass"
        >
          <template #cell(reports)="row">
            <b-nav card-header pills>
              <b-dropdown
                id="dropdown-dropleft"
                dropleft
                v-if="Array.isArray(row.item.reports.reports_entry)"
                text="Select Report..."
              >
                <b-dropdown-item
                  :to="{
                    name: 'reports',
                    query: {
                      url: reports.report_url,
                      id: id,
                      code: row.item.code,
                      title:reports.report_title
                    },
                  }"
                  v-for="(reports, index) in row.item.reports.reports_entry"
                  :key="index"
                  :title="reports.report_title"
                  >{{ reports.report_title }}</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown id="dropdown-dropleft" dropleft v-else text="Select Report...">
                <b-dropdown-item
                  :to="{
                    name: 'reports',
                    query: {
                      url: reports.report_url,
                      id: id,
                      code: row.item.code,
                      title:reports.report_title
                    },
                  }"
                  v-for="(reports, index) in row.item.reports"
                  :key="index"
                  :title="reports.report_title"
                  >{{ reports.report_title }}</b-dropdown-item
                >
              </b-dropdown>
            </b-nav>
          </template>
          <template #cell(full_name)="row">
            <p class="tourLoc">
              {{ row.item.full_name }}
            </p>
          </template>
        </b-table>
      </b-card-body></b-tab
    >
    <b-tab title="Future" active>
      <b-alert
      v-if="sent === true"
      :show="dismissCountDown"
      dismissible
      variant="success"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      Thank you for your entry request. This will be processed by the IGT admin Team shortly.
    </b-alert>
      <b-card-body class="text-center table-responsive">
        <b-table
          :items="FutureEventFilter"
          :fields="fields"
          :tbody-tr-class="rowClass"
        >
          <template #cell(reports)="row">
            <b-nav card-header pills v-if="row.item.reports !== ''">
              <b-dropdown
                id="dropdown-dropleft"
                class="SelectDropdown"
                dropleft
                v-if="Array.isArray(row.item.reports.reports_entry)"
                text="Select Report..."
              >
                <b-dropdown-item
                  :to="{
                    name: 'reports',
                    query: {
                      url: reports.report_url,
                      id: id,
                      code: row.item.code,
                      title:reports.report_title
                    },
                  }"
                  v-for="(reports, index) in row.item.reports.reports_entry"
                  :key="index"
                  :title="reports.report_title"
                  >{{ reports.report_title }}</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown id="dropdown-dropleft" dropleft v-else text="Select Report..." class="SelectDropdown">
                <b-dropdown-item
                  :to="{
                    name: 'reports',
                    query: {
                      url: reports.report_url,
                      id: id,
                      code: row.item.code,
                      title:reports.report_title
                    },
                  }"
                  v-for="(reports, index) in row.item.reports"
                  :key="index"
                  :title="reports.report_title"
                  >{{ reports.report_title }}</b-dropdown-item
                >
              </b-dropdown>
            </b-nav>
          </template>
          <template #cell(full_name)="row">
            <p class="tourLoc">
              {{ row.item.full_name }}
            </p>
          </template>
          <template #cell(is_closed)="row">
            <b-button @click="$bvModal.show(row.item.start_date); tour = row.item.full_name"
              >Enter</b-button
            >
            <b-modal :id="row.item.start_date" class='mod' hide-footer centered :title="row.item.full_name">
              <b-form @submit="onSubmit">
                <b-form-input
                  type="text"
                  v-model='tour'
                  :placeholder="row.item.full_name"
                  :disabled="selected === 1"
                  class="mt-3"
                ></b-form-input>
                <b-form-group
                  id="input-group-1"
                  label="Email address:"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="email"
                    type="email"
                    placeholder="Enter email"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="First Name:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="forename"
                    placeholder="Enter First Name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-3"
                  label="Surname:"
                  label-for="input-3"
                >
                  <b-form-input
                    id="input-3"
                    v-model="surname"
                    placeholder="Enter Surname"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-4"
                  label="Telephone No:"
                  label-for="input-4"
                >
                  <b-form-input
                    id="input-4"
                    v-model="telephone"
                    placeholder="Enter Telephone No"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-5" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="proa"
                    id="input-group-5"
                    :aria-describedby="ariaDescribedby"
                    :options='options'
                  >
                  </b-form-checkbox-group>
                </b-form-group>

                <b-button @click='$bvModal.hide(row.item.start_date)' type="submit" variant="success">Submit</b-button>
              </b-form>
            </b-modal>
          </template>
        </b-table>
      </b-card-body></b-tab
    >
  </b-tabs>
</template>

<script>
import axios from 'axios'
export default {
  name: "scheduleTable",
  props: ["id", "fields", "data", "fieldsPast", 'code'],
  data() {
    return {
      sent:false,
        tour:'',
        email: "",
        forename: "",
        surname: "",
        telephone: "",
        proa: "",
        selected:1,
        options: [
          { text: 'Professional', value: 'Pro' },
          { text: 'Amateur', value: 'Am' },
        ],
        dismissSecs: 5,
        dismissCountDown: 0,

    };
  },
  computed: {
    pastEventFilter: function() {
      return this.data.slice().reverse().filter((data) => data.days_away < 0);
    },
    FutureEventFilter: function() {
      return this.data.filter((data) => data.days_away >= 0);
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return; 
      if (this.code === item.code) return "live";
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    onSubmit(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("tour", this.tour);
      formData.append("email", this.email);
      formData.append("forename", this.forename);
      formData.append("surname", this.surname);
      formData.append("telephone", this.telephone);
      formData.append("proam", this.proa);
      console.log(formData);
      axios
        .post(
          // "https://wp-ocssport.ocs-sport.com/wp-json/contact-form-7/v1/contact-forms/1533/feedback",
          "https://wp-igtorg.ocs-sport.com/wp-json/contact-form-7/v1/contact-forms/10121/feedback",
          formData
        )
        .then((response) => {
          this.sent = true;
          return response, this.showAlert();
        });
    },
  },
};
</script>

<style scoped>
::v-deep .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

::v-deep .SelectDropdown > button{
  background-color: #fff;
  color: #000;
  position: relative;
  top: -4px;
  border-radius: 0px;
}
li.nav-item::marker {
  color: transparent;
}
.nav-link {
  margin-top: -5%;
}
::v-deep .nav-link.active {
  color: white !important;
  background-color: #2f8d3e !important;
  width: 100%;
  border-color: #2f8d3e !important;
  height: 100%;
  border-radius: 0px;
}

::v-deep .nav-link {
  color: black !important;
  width: 100%;
  font-size: 18pt;
}
.live>td>.tourLoc{
  color: #fff!important;
}
.tourLoc {
  color: #2f8d3e;
}
::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
::v-deep th {
  position: sticky;
  top: 0;
  background-color: #2f8d3e;
  z-index: 111;
}

::v-deep .live {
  color: white;
  background-color: #519c1f !important;
}
::v-deep .live > td > li > a {
  color: white;
}
.live > td > .btn{
  color: #fff;
  background-color: #33CD5C;
  position: relative;
  top: -4px;
  border-radius: 0px;
}
::v-deep .live > td > ul > div > button {
  color: #000;
  background-color: white;
}
::v-deep .live > td {
  padding-bottom: 0px;
} 
::v-deep .notlive > td {
  padding-bottom: 0px;
}
::v-deep th:nth-child(2){
  text-align: left;
}
::v-deep th:nth-child(3){
  text-align: left;
}
::v-deep th:last-child{
  text-align: left;
}
::v-deep td:nth-child(2){
  text-align: left;
}
::v-deep td:nth-child(3){
  text-align: left;
}
@media only screen and (max-width: 480px) {
  ::v-deep th:nth-child(3){
    display: none;
  }
    ::v-deep td:nth-child(3){
    display: none;
  }
  ::v-deep .dropdown>.btn{
    font-size: 10pt;
  }
}
</style>
