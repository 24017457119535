<template>
  <div>
    <div v-if="tabs === false">
      <img class="sponsor" :src="data.event_sponsor_logo" />
    </div>
    <div class="HBHtitle">
      <h3>{{ data.full_name }} - {{ data.round_head }}</h3>
      <p>
        {{ data.course_dates }}
        <br>
          Projected Finish Time - {{ data.pft_time }}
        <br>
        <span>
          Last Score Update {{ data.last_scores_update_date }}
          {{ data.last_scores_update_time }} - (Local Time)
        </span>
      </p>
    </div>
    <div class="table-responsive">
      <table class="tableMob">
        <thead>
          <tr class="HBHtitleRow">
            <th class="CP" style="border-top: 1px solid #263056;"></th>
            <th class="CN" style="border-top: 1px solid #263056;"></th>
            <th class="CF" style="border-top: 1px solid #263056;"></th>
            <th class="CT"></th>
            <th class="CV"></th>
            <th class="CS">
              1
            </th>
            <th class="CS">
              2
            </th>
            <th class="CS">
              3
            </th>
            <th class="CS">
              4
            </th>
            <th class="CS">
              5
            </th>
            <th class="CS">
              6
            </th>
            <th class="CS">
              7
            </th>
            <th class="CS">
              8
            </th>
            <th class="CS">
              9
            </th>
            <th class="CS">
              Out
            </th>
            <th class="CS">
              10
            </th>
            <th class="CS">
              11
            </th>
            <th class="CS">
              12
            </th>
            <th class="CS">
              13
            </th>
            <th class="CS">
              14
            </th>
            <th class="CS">
              15
            </th>
            <th class="CS">
              16
            </th>
            <th class="CS">
              17
            </th>
            <th class="CS">
              18
            </th>
            <th class="CS">
              IN
            </th>
            <th class="CS">
              TOT
            </th>
          </tr>
          <tr class="HBHtitleRow">
            <th class="CP">
              Pos
            </th>
            <th class="CN">
              Player Name
            </th>
            <th class="CF">
              Nat.
            </th>
            <th class="CT">
              Tot
            </th>
            <th class="CV">
              VsPar
            </th>
            <th
              class="CS"
              v-for="(course, index) in data.course_par.split(',').slice(0, 9)"
              :key="index + Math.random()"
            >
              {{ course }}
            </th>
            <th class="CS">
              {{ data.course_out_par.slice(1) }}
            </th>
            <th
              class="CS"
              v-for="(course, index) in data.course_par.split(',').slice(9, 18)"
              :key="index + Math.random()"
            >
              {{ course }}
            </th>
            <th class="CS">
              {{ data.course_in_par.slice(1) }}
            </th>
            <th class="CS">
              {{ data.course_total_par }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="RT"
            v-for="(playerData, index) in scores"
            :key="index + Math.random()"
          >
            <td v-if="playerData.pos !== 'CUT'">
              <span
                v-if="
                  index !== 0 &&
                    (playerData.tied !== '=' ||
                      scores[index - 1].pos !== playerData.pos)
                "
                class="CP pos"
              >
                {{ playerData.pos }}
              </span>
              <span v-else-if="index === 0" class="CP pos">
                {{ playerData.pos }}
              </span>
              <span v-else></span>
            </td>

            <td
              v-if="playerData.pos !== 'CUT'"
              :title="playerData.member_no"
              :class="{
                'text-up': playerData.posChang == 'up',
                'text-down': playerData.posChang == 'down',
              }"
            >

                {{ playerData.name }}
              <span v-if="playerData.pro_ind === 'Am'">(a)</span>
            </td>
            <td
              v-if="playerData.pos !== 'CUT'"
              class="CF"
              :title="playerData.nationality"
            >
              <span>
                <img
                  class="flag"
                  :src="
                    (config.VUE_APP_FLAG_URL +
                      playerData.nationality)
                      | lowercase
                  "
                />
              </span>
            </td>
            <td v-if="playerData.pos !== 'CUT'" class="CT">
              <span v-if="playerData.vspar < 0" :class="up">
                {{ playerData.vspar }}
              </span>
              <span v-else-if="playerData.vspar > 0" :class="down">
                {{ playerData.vspar }}
              </span>
              <span v-else>
                {{ playerData.vspar }}
              </span>
            </td>

            <td v-if="playerData.pos !== 'CUT'">
              <span
                v-if="playerData['vspar_R' + data.pft_round] < 0"
                :class="up"
              >
                {{ playerData["vspar_R" + data.pft_round] }}
              </span>
              <span
                v-else-if="playerData['vspar_R' + data.pft_round] > 0"
                :class="down"
              >
                {{ playerData["vspar_R" + data.pft_round] }}
              </span>
              <span v-else>
                {{ playerData["vspar_R" + data.pft_round] }}
              </span>
            </td>
            <td class="cut" colspan="30" v-if="playerData.pos === 'CUT'">
              {{ playerData.pos }} {{ playerData.cut_round }}
            </td>
            <td
              class="CS"
              v-for="(r1, index) in (
                playerData['hole_scores_R' + data.pft_round] || ''
              )
                .split(',')
                .slice(0, 9)"
              :key="index + Math.random()"
            >
              <span
                class="twoUnder"
                v-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '').split(
                    ','
                  )[index] == -2
                "
              >
                {{ r1 }}
              </span>
              <span
                class="oneUnder"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '').split(
                    ','
                  )[index] == -1
                "
              >
                {{ r1 }}
              </span>
              <span
                class="oneOver"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '').split(
                    ','
                  )[index] == 1
                "
              >
                {{ r1 }}
              </span>
              <span
                class="twoOver"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '').split(
                    ','
                  )[index] == 2
                "
              >
                {{ r1 }}
              </span>
              <span v-else>
                {{ r1 }}
              </span>
            </td>
            <td class="CS">
              <span
                v-if="
                  sum(
                    (playerData['hole_scores_R' + data.pft_round] || '')
                      .split(',')
                      .slice(0, 9)
                  ) > data.course_out_par
                "
                class="down"
              >
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(0, 9)
                  )
                }}
              </span>
              <span
                v-else-if="
                  sum(
                    (playerData['hole_scores_R' + data.pft_round] || '')
                      .split(',')
                      .slice(0, 9)
                  ) < data.course_out_par
                "
                class="up"
              >
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(0, 9)
                  )
                }}
              </span>
              <span v-else>
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(0, 9)
                  )
                }}
              </span>
            </td>
            <td
              class="CS"
              v-for="(r1, index) in (
                playerData['hole_scores_R' + data.pft_round] || ''
              )
                .split(',')
                .slice(9, 18)"
              :key="index + Math.random()"
            >
              <span
                class="twoUnder"
                v-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '')
                    .split(',')
                    .slice(9, 18)[index] == -2
                "
              >
                {{ r1 }}
              </span>
              <span
                class="oneUnder"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '')
                    .split(',')
                    .slice(9, 18)[index] == -1
                "
              >
                {{ r1 }}
              </span>
              <span
                class="oneOver"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '')
                    .split(',')
                    .slice(9, 18)[index] == 1
                "
              >
                {{ r1 }}
              </span>
              <span
                class="twoOver"
                v-else-if="
                  (playerData['hole_vspars_R' + data.pft_round] || '')
                    .split(',')
                    .slice(9, 18)[index] == 2
                "
              >
                {{ r1 }}
              </span>
              <span v-else>
                {{ r1 }}
              </span>
            </td>
            <td class="CS">
              <span
                v-if="
                  sum(
                    (playerData['hole_scores_R' + data.pft_round] || '')
                      .split(',')
                      .slice(9, 18)
                  ) > data.course_in_par
                "
                class="down"
              >
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(0, 9)
                  )
                }}
              </span>
              <span
                v-else-if="
                  sum(
                    (playerData['hole_scores_R' + data.pft_round] || '')
                      .split(',')
                      .slice(9, 18)
                  ) < data.course_in_par
                "
                class="up"
              >
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(9, 18)
                  )
                }}
              </span>
              <span v-else>
                {{
                  sum(
                    (playerData["hole_scores_R" + data.pft_round] || "")
                      .split(",")
                      .slice(9, 18)
                  )
                }}
              </span>
            </td>
            <td v-if="data.rounds_played > 1" class="CS">
              <span
                v-if="
                  playerData['hole_scores_R' + data.pft_round] >
                    data.course_total_par
                "
                class="down"
              >
                {{ playerData["score_R" + data.pft_round] }}
              </span>
              <span
                v-else-if="
                  playerData['hole_scores_R' + data.pft_round] <
                    data.course_total_par
                "
                class="up"
              >
                {{ playerData["score_R" + data.pft_round] }}
              </span>
              <span v-else>
                {{ playerData["score_R" + data.pft_round] }}
              </span>
            </td>
            <td v-else class="CS">
              <span
                v-if="playerData.score > data.course_total_par"
                class="down"
              >
                {{ playerData.score }}
              </span>
              <span
                v-else-if="playerData.score < data.course_total_par"
                class="up"
              >
                {{ playerData.score }}
              </span>
              <span v-else>
                {{ playerData.score }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <b-row>
        <b-col cols='8'>
          <p class='lead'>
            Key:
            <span class='great scoreKey'></span>
            Eagle or Better
            <span class='good scoreKey'></span>
            Birdie 
            <span class='over scoreKey'></span>
            Bogey
            <span class='bad scoreKey'></span>
            Double Bogey or Worse
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

export default {
  name: "hbh",
  props: ["tabs", 'data', 'scores', 'previousData', 'config'],
  data() {
    return {
      up: "up",
      down: "down",
      CS: "CS",
      CV: "CV",
      CT: "CT",
      CN: "CN",
      CP: "CP",
      CF: "CF",
    };
  },
  computed: {
    roundsPlayer: function() {
      return parseInt(this.data.pft_round);
    }
  },
  watch: {
    scores: {
      immediate: true,
      handler(newValue, oldValue) {
        newValue.forEach((value1, key1) => {
          oldValue.forEach((value2, key2) => {
            if (value1.id === value2.id) {
              if (key1 < key2) {
                value1.posChang = "up";
              } else if (key1 > key2) {
                value1.posChang = "down";
              }
            }
          });
        });

        console.log(newValue);
      },
      deep: true
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  },
  methods: {
    sum: function(nums) {
      let result = 0;
      nums.forEach(function(n) {
        result += n * 1;
      });
      return result;
    }
  },
};
</script>

<style scoped>
tr.RT:nth-child(even) {
  background-color: #fff;
}
#hbh{
  padding-top: 2%;
  padding-bottom: 2%;
}
.main{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.sponsor {
  margin-top: -10%;
  width: 20%;
  padding-left: 10%;
}
.HBHtitle {
  text-align: center;
  background-color: #fff;
  color: #2F8D3E;
  padding-top: 20px;
  padding-bottom: 10px;
}
.title {
  text-align: center;
  background-color: #2F8D3E;
  color: white;
}
.HBHtitleRow {
  text-align: center;
  background-color: #2F8D3E;
  color: white;
}
td{
  padding: 6px;
}
.pos {
  color: white;
  background-color: #2F8D3E;
  padding: 5px 15px 5px 15px;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.oneUnder {
  color: #a52a2a !important;
}

.twoUnder {
  color: #3c9933 !important;
}

.oneOver {
  color: #1e90ff !important;
}

.twoOver {
  color: #e07020 !important;
}

.RT {
  border: 1px solid black;
  text-align: center;
}
.CP {
  width: 2%;
  text-align: center;
}
.CN {
  width: 20%;
}
.CF {
  width: 5%;
  text-align: center;
}
.CT {
  width: 3%;
  border: 1px solid black;
  text-align: center;
}
.CV {
  width: 3%;
  border: 1px solid black;
  text-align: center;
}
.CS {
  border: 1px solid black;
  text-align: center;
  width: 3%;
  
}
td.CS{
  color: black;
}
td>span{
  color: black;
}
.cut {
  background-color: red;
  color: white;
}
.scoreKey{
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 18px;
}
.great{
    background: #3c9933 !important;
}
.good{
    background: #A52A2A !important;
}
.over{
    background: #1E90FF !important;
}
.bad{
background: #e07020 !important;
}
.lead{
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.4;
  padding-left: 20px;
}
@media only screen and (max-width: 480px) {
  .tableMob {
    width: 275%;
  }
}
</style>
