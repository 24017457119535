<template>
  <table>
    <thead v-if="this.id !== this.data.season_code">
      <tr>
        <td :class="noData">
          No Data Loaded Please Select a Season & Report
        </td>
      </tr>
    </thead>
    <thead v-else>
      <tr>
        <th :class="[cell, fields]">
          Pos
        </th>
        <th :class="[cell, fields]" class="nameLeft">
          Name
        </th>
        <th :class="[cell, fields]">
          Nat
        </th>
        <th :class="[cell, fields]">
          {{data.value_1_head}}
        </th>
        <th :class="[cell, fields]">
          {{data.oom_header}}
        </th>
      </tr>
    </thead>
    <tbody v-if="this.id !== this.data.season_code"></tbody>
    <tbody v-else>
      <tr v-if="data.oom_available == 'N'">
        <td colspan="5" id="NS">
          No Stats
        </td>
      </tr>
      <tr v-for="(stats, index) in data.oom.oom_entry" :key="index">
        <td :class="[cell, name, posCol]">
          <span class="pos">
            {{ stats.pos }}
          </span>
        </td>
        <td :class="[cell, name]">
            {{ stats.name }}
        </td>
        <td  :class="cell">
          <span v-if='stats.pos !== "CUT"'>
            <img
              :class="flag"
              :src="
                (config.VUE_APP_FLAG_URL +
                  stats.nationality)
                  | lowercase
              "
            />
          </span>
        </td>
        <td :class="cell">
          {{ stats.oom_value }}
        </td>
        <td :class="cell">
          {{ stats.played }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "statsTable",
  props: ["id", "stat", "data", 'config'],
  data() {
    return {
      noData: "noData",
      flag: "flag",
      cell: "cell",
      fields: "fields",
      name: "name",
      posCol: "posCol",
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
td.cell.name {
  text-align: left;
}
td.cell.name > a {
  color: #519c1f;
  font-weight: 800;
}
th.nameLeft {
  text-align: left;
}
td {
  font-size: 13pt;
}
.noData {
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}

.fields {
  background-color: #2f8d3e;
  color: white;
}

.cell {
  width: 15%;
  text-align: center;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
th {
  position: sticky;
  top: 0;
}

.pos {
  padding: 5px;
  background-color: #2f8d3e;
  color: #ffffff;
  padding-top: 6px;
  padding-bottom: 6px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -6px;
  margin-bottom: -5px;
}
.posCol {
  padding-top: 6px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  text-align: center!important;
}

#NS {
  text-align: center;
  font-size: 1.5rem;
  padding-top: 2%;
  padding-bottom: 2%;
}

@media only screen and (max-width: 480px) {
  .noData {
    height: 50vh;
  }
  .cell {
    width: 1%;
  }
  .name {
    width: 3%;
  }
}
</style>
