var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"top"},[_c('div',[_c('b-container',{staticClass:"selectRD"},[_c('b-row',[_c('b-col',{staticClass:"dropSel",attrs:{"lg":"6","md":"4","cols":"12","order":"2","order-lg":"1","order-md":"1"}},[_c('div',{class:_vm.drop},[_c('b-nav',{attrs:{"card-header":"","pills":""}},[(Array.isArray(_vm.tmparams.reports.reports_entry))?_c('b-dropdown',{attrs:{"text":_vm.title}},_vm._l((_vm.filterExemptions),function(reports,index){return _c('b-dropdown-item',{key:index,attrs:{"title":reports.report_url,"value":reports.report_title},on:{"click":_vm.changeReport}},[_vm._v(_vm._s(reports.report_title))])}),1):_c('b-dropdown',{attrs:{"text":"Please Select a Report"}},_vm._l((_vm.tmparams.reports),function(reports,index){return _c('b-dropdown-item',{key:index,attrs:{"title":reports.report_url,"value":reports.report_title},on:{"click":_vm.changeReport}},[_vm._v(_vm._s(reports.report_title))])}),1)],1)],1)]),_c('b-col',{attrs:{"lg":"6","md":"8","cols":"12","order":"1","order-lg":"1"}},[_c('b-img',{staticClass:"sponsor",attrs:{"src":_vm.tmparams.sponsor_logo}})],1)],1)],1)],1)]),_c('div',{staticClass:"mid"},[_c('div',{staticClass:"reports"},[(
          this.currentReport.indexOf('tmentry') !== -1 ||
            this.currentReport.indexOf('tmdraw') !== -1 ||
            this.currentReport.indexOf('tmresult') !== -1 ||
            this.currentReport.indexOf('tmoomt') !== -1 ||
            (this.currentReport.indexOf('tmscore') !== -1 &&
              _vm.data.match_play === 'Y')
        )?[_c('ReportTable',{attrs:{"data":_vm.data,"home":_vm.home,"title":_vm.title,"config":_vm.config,"team":_vm.teamEvent,"match":_vm.tmparams.match_play,"season":_vm.tmparams.tm_params.season_code}})]:_vm._e(),(
          this.currentReport.indexOf('tmscore') !== -1 ||
            this.currentReport.indexOf('tmlsmon') !== -1
        )?[_c('b-tabs',[_c('b-tab',{attrs:{"title":"Leaderboard","active":""}},[_c('ReportTable',{attrs:{"data":_vm.data,"previousData":_vm.previousData,"home":_vm.home,"title":_vm.title,"config":_vm.config,"team":_vm.teamEvent,"season":_vm.tmparams.tm_params.season_code}})],1),_c('b-tab',{attrs:{"title":"Hole By Hole"}},[_c('HBH',{attrs:{"data":_vm.data,"scores":_vm.data.scores.scores_entry,"previousData":_vm.previousData,"config":_vm.config}})],1)],1)]:_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }